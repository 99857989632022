<template>
    <div class="mb-4">
        <div class="mb-4">
            <h4 class="font-weight-600">Change Password</h4>
        </div>
        <form @submit.prevent="changePass()">
            <div class="row">
                <div class="col-md-8 col-xl-6">
                    <b-alert
                        v-if="error"
                        class="p-2 text-center"
                        show
                        variant="danger"
                        >{{ error }}</b-alert
                    >
                    <div class="form-group">
                        <label class="mx-3 text-gray">Old Password</label>
                        <input
                            v-model="oldPassword"
                            type="text"
                            class="form-control"
                            placeholder="Old Password"
                        />
                    </div>
                    <div class="form-group">
                        <label class="mx-3 text-gray">New Password</label>
                        <input
                            v-model="newPassword"
                            type="text"
                            class="form-control"
                            placeholder="New Password"
                        />
                    </div>
                    <div class="form-group">
                        <label class="mx-3 text-gray">Re-New Password</label>
                        <input
                            v-model="confirmPassword"
                            type="text"
                            class="form-control"
                            placeholder="Re-New Password"
                        />
                    </div>
                    <div>
                        <button
                            class="btn btn-quiz text-uppercase mx-auto"
                            type="submit"
                            :disabled="sending"
                        >
                            <b-spinner
                                v-if="sending"
                                variant="light"
                            ></b-spinner>
                            <span v-else class="text-white">Change</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { updatePassword } from "@/API/auth";
export default {
    name: "ChangePassword",
    data() {
        return {
            sending: false,
            oldPassword: null,
            newPassword: null,
            confirmPassword: null,
            error: null,
        };
    },
    methods: {
        changePass() {
            this.error = null;

            if (
                !this.oldPassword ||
                !this.newPassword ||
                !this.confirmPassword
            ) {
                this.error = "Fill out all fields!";
                return;
            }

            this.sending = true;
            const data = {
                current_password: this.oldPassword,
                new_password: this.newPassword,
                new_password_confirmation: this.confirmPassword,
            };

            updatePassword(data).then((res) => {
                // console.log(res.data);
                this.sending = false;

                if (res?.status == 200) {
                    this.$notify(res.data.message);
                    this.resetInputs();

                    return;
                }

                if (res?.response?.status == 404) {
                    this.$router.push("/404");
                    return;
                }

                if (res?.response?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                if (res?.response?.data?.message) {
                    this.error = res.response.data.message;
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            });
        },
        resetInputs() {
            this.oldPassword = null;
            this.newPassword = null;
            this.confirmPassword = null;
        },
    },
};
</script>

